import * as React from "react"
import {Card, Container} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";

// markup
const ImprintPage = () => {
    return <Layout>
        <Seo title={'Impressum'} meta={[{name: 'robots', content: 'noindex,nofollow'}]}/>
        <Container>
            <h1 className={'my-5'}>Impressum</h1>
            <Card className={'shadow p-3 meta-content'}>
                <Card.Body>
                    <p>Oualid Ajjour<br/>
                        Schulstr. 5<br/>
                        55578<br/>
                        Tel: +49 (0) 6701 4230882<br/>
                        <a href="mailto:kontakt@schuljournal.de">kontakt@schuljournal.de</a>
                    </p>
                    <meta name="generator" content="Impressum-Generator der Kanzlei Hensche Rechtsanwälte"/>
                    <p>&nbsp;</p>
                    <h1>Disclaimer - rechtliche Hinweise</h1>
                    <p><strong>Auskunfts- und Widerrufsrecht</strong></p>
                    <p>Sie haben jederzeit das Recht, sich unentgeltlich und unverz&#252;glich &#252;ber die zu Ihrer Person erhobenen Daten
                        zu erkundigen. Ebenfalls k&#246;nnen Sie Ihre Zustimmung zur Verwendung Ihrer angegebenen pers&#246;nlichen Daten mit
                        Wirkung f&#252;r die Zukunft widerrufen. Hierf&#252;r wenden Sie sich bitte an den im Impressum angegebenen
                        Diensteanbieter.</p>
                    <p>&nbsp;</p>
                    <p><strong>Disclaimer (Haftungsausschluss)</strong></p>
                    <p><strong>1. Haftung f&#252;r Inhalte</strong></p>
                    <p>Als Diensteanbieter sind wir gem&#228;&#223; &#167; 7 Abs. 1 TMG f&#252;r eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach &#167;&#167; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                        verpflichtet, &#252;bermittelte oder gespeicherte fremde Informationen zu &#252;berwachen oder nach Umst&#228;nden zu
                        forschen, die auf eine rechtswidrige T&#228;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung
                        von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&#252;hrt. Eine diesbez&#252;gliche Haftung ist
                        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&#246;glich. Bei Bekanntwerden von
                        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    <p><strong>2. Haftung f&#252;r Links</strong></p>
                    <p>Diese Website enth&#228;lt Links zu externen Webseiten Dritter, auf deren Inhalte kein Einfluss genommen werden kann.
                        Deshalb kann f&#252;r diese fremden Inhalte auch keine Gew&#228;hr &#252;bernommen werden. F&#252;r die Inhalte der
                        verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
                        zum Zeitpunkt der Verlinkung auf m&#246;gliche Rechtsverst&#246;&#223;e &#252;berpr&#252;ft. Rechtswidrige Inhalte waren
                        zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige
                        Links umgehend von dieser Website auf die rechtsverletzende Site entfernen.</p>
                    <p><strong>3. Urheberrecht</strong></p>
                    <p>Die durch die Diensteanbieter, deren Mitarbeiter und beauftragte Dritte erstellten Inhalte und Werke auf diesen Seiten
                        unterliegen dem deutschen Urheberrecht. Die Vervielf&#228;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                        au&#223;erhalb der Grenzen des Urheberrechtes bed&#252;rfen der vorherigen schriftlichen Zustimmung des jeweiligen Autors
                        bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&#252;r den privaten, nicht kommerziellen Gebrauch
                        gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden derartige Inhalte umgehend entfernen.</p>
                    <p>&nbsp;</p>
                    <p>Dieses Impressum wurde mit Hilfe des <a target="_blank"  rel="noopener noreferrer"
                                                               href="http://www.hensche.de/impressum-generator.html">Impressum-Generators</a> von <a target="_blank"  rel="noopener noreferrer"
                                                                                                                                                     href="http://www.hensche.de/Rechtsanwalt_Arbeitsrecht_Frankfurt.html">HENSCHE Rechtsanw&auml;lte, Frankfurt (Westend)</a>
                        erstellt.</p>
                </Card.Body>
            </Card>
        </Container>
    </Layout>
}

export default ImprintPage
